import * as yup from 'yup';
import { REGEXP_EMAIL } from '@app/constants/validation';
// https://github.com/jquense/yup/issues/2135
const validationSchema = (translateFn) => yup.object().shape({
    isAuthorized: yup.boolean(),
}).when(([values], schema) => {
    if (values.isAuthorized) {
        return schema.shape({
            message: yup.string().required().max(1000, ' '),
            topic: yup.string().max(40, ' '),
        });
    }
    return schema.shape({
        email: yup.string().min(1, translateFn('support.send_request.messages.email_empty'))
            .matches(REGEXP_EMAIL, translateFn('support.send_request.messages.email_invalid')).max(40, ' '),
        name: yup.string().min(1, translateFn('support.send_request.messages.name_empty')).max(40, ' '),
        message: yup.string().min(1, ' ').max(1000, ' '),
    });
});
export default validationSchema;
