import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodyMediumSemiBold, BodySmall } from '@components/styled/Typography';
const UserLabelContainer = styled(ContainerRow)(props => ({
    width: 'unset',
    height: 'auto',
    backgroundColor: '#F8F9FA',
    padding: `${props.theme.spacing_sizes.m}px ${props.theme.spacing_sizes.xm}px`,
    gap: props.theme.spacing_sizes.m,
    borderRadius: 6,
}));
const TextContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.25,
}));
const PublicName = styled(BodyMediumSemiBold)();
const Nickname = styled(BodySmall)(props => ({
    color: props.theme.palette.text.secondary,
    lineHeight: '18px',
}));
const ImageWrapper = styled.div(() => ({
    width: 36,
    minWidth: 36,
    height: 36,
    borderRadius: '50%',
}));
const UserLabel = ({ nickname, publicName, imageUrl = undefined, }) => (_jsxs(UserLabelContainer, { children: [_jsx(ImageWrapper, { children: _jsx(AvatarImage, { publicName: publicName, imageUrl: imageUrl, size: 'thumbnail' }) }), _jsxs(TextContainer, { children: [_jsx(PublicName, { children: publicName }), _jsx(Nickname, { children: nickname ? `@${nickname}` : '' })] })] }));
export default UserLabel;
